import axios from "axios";
import { configJson } from "../firebaseUtils";
import { getChannelToken_v1UidNull } from "../Apis/getChannelToken_v1UidNull";

const queryPartnerAPI = ["getUserInfo"];

//BUSCA O USUÁRIO PELO TELEFONE
export function getUserInfo_v1(userInfo) {
  return new Promise((resolve, reject) => {
    getChannelToken_v1UidNull()
      .then((resultTriiboHeader) => {
        axios
          .post(configJson.API_HOST_V1 + "getUserInfo_v1", {
            triiboHeader: resultTriiboHeader,
            queryPartnerAPI,
            userInfo,
          })
          .then(async (result) => {
            // console.log("RESULT_API_GET_USER_INFO: ", result.data);
            resolve(result.data.success.userInfo);
          })
          .catch((error) => {
            console.log("ERROR_API_GET_USER_INFO: ", error);
            reject(error);
          });
      })
      .catch((error) => {
        reject(error);
        console.log("ERROR_GET_CHANNEL_TOKEN_GET_USER_INFO: ", error);
      });
  });
}

//BUSCA USUÁRIO PELO CPF
export function getUserInfo_v1WithDocument(userInfo) {
  return new Promise((resolve, reject) => {
    getChannelToken_v1UidNull()
      .then((resultTriiboHeader) => {
        axios
          .post(configJson.API_HOST_V1 + "getUserInfo_v1", {
            triiboHeader: resultTriiboHeader,
            queryPartnerAPI,
            userInfo,
          })
          .then(async (result) => {
            // console.log("RESULT_API_GET_INFO_WITH-DOCUMENT: ", result.data);

            // if (result.data.success.userInfo === null) {
            //   //TODO: verificar cellphone
            //   const resultCreate = await login(
            //     "+" + cellPhone.replace(/\D/g, "")
            //   );

            //   resolve(resultCreate.data.user.uId);
            // } else {
            //   resolve(result.data.success.userInfo.uId);
            // }

            resolve(result.data.success.userInfo);
          })
          .catch((error) => {
            console.log("ERROR_GET_USER_INFO_WITH-DOCUMENT: ", error);
            reject(error);
          });
      })
      .catch((error) => {
        console.log("ERROR_GET_CHANNEL_TOKEN_GET_USER_INFO: ", error);
        reject(error);
      });
  });
}
