import axios from "axios";
import { configJson, firebaseDatabase } from "../firebaseUtils";
import { hashTriibo1, validateRegistrationDate } from "../../utils/helpers";

export function getChannelToken_v1UidNull() {
  const triiboHeader = {
    apiToken: configJson.apiToken,
    channelGroup: configJson.channelGroup,
    channelName: configJson.channelName,
    uId: null,
    channelId: configJson.channelId,
    sessionId: "001",
    transactionId: "002",
  };

  return new Promise((resolve, reject) => {
    //capturando dados do Storage
    const channelObj = JSON.parse(
      localStorage.getItem("ivoclarChannelTokenUIdNull")
    );
    const valideDate = validateRegistrationDate(
      channelObj === null ? null : channelObj.registrationDate
    );

    //token não existe no storage
    if (
      channelObj === null ||
      channelObj.id === null ||
      channelObj.id === "" ||
      channelObj.id === undefined ||
      !valideDate
    ) {
      const passPhrase = firebaseDatabase
        .ref("triibo-auth-api-channel")
        .push().key;
      const challenge = hashTriibo1(
        configJson.apiToken,
        passPhrase,
        configJson.channeltoken
      );

      axios
        .post(configJson.API_HOST_V1 + "getChannelToken_v1", {
          triiboHeader: {
            apiToken: configJson.apiToken,
            channelGroup: configJson.channelGroup,
            channelName: configJson.channelName,
            uId: null,
            passPhrase: passPhrase,
            challenge: challenge,
            channelId: configJson.channelId,
          },
        })
        .then((result) => {
          // console.log("RESULTADO GET CHANNEL TOKEN", result);
          localStorage.setItem(
            "ivoclarChannelTokenUIdNull",
            '{ "id":"' +
              challenge +
              '", "registrationDate":' +
              Date.now() +
              " }"
          );

          triiboHeader.channelTokenId = challenge;

          resolve(triiboHeader);
        })
        .catch((error) => {
          console.log("ERRO GET CHANNEL TOKEN", error.response);
          reject(error);
        });
    }
    //token existe no storage
    else {
      triiboHeader.channelTokenId = channelObj.id;
      resolve(triiboHeader);
    }
  });
}
