import { createContext, useState } from "react";

export const SignUpFormContext = createContext({});

function ProvideSignUpForm({ children }) {
  const [cpfUser, setCpfUserContext] = useState("");
  const [nameUser, setNameUser] = useState("");
  const [lastNameUser, setLastNameUser] = useState("");
  const [emailUser, setEmailUser] = useState("");
  const [userRole, setUserRole] = useState("");
  const [entityType, setEntityType] = useState("");

  return (
    <SignUpFormContext.Provider
      value={{
        setCpfUserContext,
        setNameUser,
        setLastNameUser,
        setEmailUser,
        setEntityType,
        setUserRole,
        userRole,
        entityType,
        cpfUser,
        nameUser,
        lastNameUser,
        emailUser,
      }}
    >
      {children}
    </SignUpFormContext.Provider>
  );
}

export default ProvideSignUpForm;
