import axios from "axios";
import { getChannelToken_v1 } from "../Apis/getChannelToken_v1";
import { configJson } from "../firebaseUtils";

export async function setUserInfo_v1(uId, userInfo) {
  return new Promise(function (resolve, reject) {
    getChannelToken_v1(uId)
      .then((resultTriiboHeader) => {
        axios
          .post(configJson.API_HOST_V1 + "setUserInfo_v1", {
            triiboHeader: resultTriiboHeader,
            queryPartnerAPI: ["setUserInfo"],
            userInfo,
          })
          .then(async (result) => {
            resolve({ userInfo: result.data.success.newUserInfo });
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        //console.log('ERROR GET CHANNEL TOKEN ===>', error);
        reject(error);
      });
  });
}
