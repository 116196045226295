import { firebaseAuth } from "../services/firebaseUtils";

//função de logout
export function logout() {
  return new Promise((resolve, reject) => {
    firebaseAuth
      .signOut()
      .then(function (logout) {
        resolve(logout);
      })
      .catch(function (error) {
        console.log("Erro de logout", error);
      });
  });
}

export function checkUserStatus() {
  return new Promise((resolve, reject) => {
    firebaseAuth.onAuthStateChanged(function (user) {
      return resolve(user);
    });
  });
}

export function loginUser(email, password) {
  return new Promise((resolve, reject) => {
    firebaseAuth
      .signInWithEmailAndPassword(email, password)
      .then(function (login) {
        // console.log("API_LOGIN_FIREBASE: ", login);
        return resolve(login);
      })
      .catch(function (error) {
        console.log("API_LOGIN_FIREBASE_ERROR: ", error);
        return reject(error);
      });
  });
}
